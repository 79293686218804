body {
    background: #f2f2f2;
}

.payment {
    border: 1px solid #f2f2f2;
    height: 678px;
    border-radius: 20px;
    background: #fff;
    overflow-y: hidden;
}
.payment_header {
    background: rgba(255, 102, 0, 1);
    padding: 20px;
    border-radius: 20px 20px 0px 0px;
}

.check {
    margin: 0px auto;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #fff;
    text-align: center;
}

.check i {
    vertical-align: middle;
    line-height: 50px;
    font-size: 30px;
}

.content {
    text-align: center;
}

.content h1 {
    font-size: 25px;
    padding-top: 225px;
}

.content a {
    width: 200px;
    height: 35px;
    color: #fff;
    font-weight: bold;
    border-radius: 30px;
    padding: 5px 10px;
    background: rgba(255, 102, 0, 1);
    transition: all ease-in-out 0.3s;
}

.content a:hover {
    text-decoration: none;
    color: #fff !important;
    font-weight: bold;
}
