.wpwl-form-card {
    background-color: orange; 
}
.wpwl-button-pay {
    background-color: white;
    color: orangered;
    border-color: orangered;
}
.wpwl-button-pay:hover {
    background-color: orangered;
    color: white;
    border-color: white;
}
.wpwl-button-pay:focus {
    background-color: white;
    color: orangered;
    border-color: orangered;
}
.wpwl-button-pay:active {
    background-color: orangered;
    color: white;
    border-color: white;
}

.wpwl-container {
    margin: auto !important;   
}